'use client';

import { useRouter } from 'next/navigation';
import { Container, Flex } from '@radix-ui/themes';

import { packAddress } from '@/lib/utils/url';

import TrustedByText from '@/views/design/TrustedByText';
import NewsletterWidget from '@/views/widgets/Newsletter';
import PropertySearchForm from '@/views/forms/PropertySearch';

const i18n = {
  links: {
    prices: '/prices/[propertyId]',
  },
};

export default function HomeView() {
  const router = useRouter();
  return (
    <div>
      <section className="mx-auto w-full max-w-screen-lg">
        <div className="my-12 w-full px-4">
          <TrustedByText />

          <div className="mt-6">
            <PropertySearchForm
              onSubmit={(data) => {
                router.push(
                  i18n.links.prices.replace(
                    '[propertyId]',
                    packAddress(data['property-address']),
                  ),
                );
              }}
            />
          </div>
        </div>
      </section>

      <Container size="3">
        <Flex direction="column" gap="8">
          <NewsletterWidget />
        </Flex>
      </Container>
    </div>
  );
}
