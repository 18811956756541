import Typed from 'typed.js';
import { useEffect, useRef } from 'react';

const i18n = {
  en: {
    trustedBy: 'Trusted by',
    households: 'households',
    to: 'to',
    theirLawn: 'their lawn.',
    services: [
      'mow',
      // 'blow',
      'edge',
      'clear',
      'trim',
      // 'weed',
      // 'fertilize',
      // 'aerate',
    ],
  },
};

export default function TrustedByTyped() {
  // Create reference to store the DOM element containing the animation
  const el = useRef(null);

  useEffect(() => {
    const typed = new Typed(el.current, {
      strings: i18n.en.services,
      loop: true,
      typeSpeed: 150,
      backSpeed: 70,
      backDelay: 1200,
      showCursor: true,
      cursorChar: '|',
    });

    return () => {
      // Destroy Typed instance during cleanup to stop animation
      typed.destroy();
    };
  }, []);

  return (
    <div className="flex flex-col justify-center text-center text-xl md:text-2xl lg:text-3xl">
      <span>
        {i18n.en.trustedBy}{' '}
        <span className="bg-gradient-to-r from-pink-500 to-violet-500 bg-clip-text font-bold text-transparent underline">
          {i18n.en.households}
        </span>{' '}
        {i18n.en.to}{' '}
      </span>
      <div>
        <span
          className="bg-gradient-to-r from-emerald-500 to-green-500 bg-clip-text font-bold text-transparent underline"
          ref={el}
          aria-label={i18n.en.services.join(', ')}
        >
          {i18n.en.services[0]}
        </span>
        <span className="2xs:inline block">{i18n.en.theirLawn}</span>
      </div>
    </div>
  );
}
